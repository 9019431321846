#empty-page {
    width: 80%;
    margin-inline: 10%;
    text-align: center;
    padding: 0;
}

#empty-page svg {
    width: 200px;
    color: var(--mid-grey);
    margin-block: 50px;
    opacity: .2;
}

#empty-page h1 {
    margin-block: 20px;
    font-size: 40px;
    color: var(--dark-grey);
}

#empty-page a {
    font-size: 20px;
    color: var(--dark-grey);
    text-decoration: none;
    transition: color .2s;
}

#empty-page a:hover{
    color: var(--main);
}