#event-page h2.date {
    color: var(--main);
    font-size: 17px;
    text-align: center;
}

#event-page h1.title {
    text-align: center;
    margin-top: 10px;
    font-size: 32px;
    font-weight: 600;
}

#event-page .content {
    width: 60%;
    margin-inline: 20%;
}

#event-page .box .content {
    width: unset;
    margin-inline: unset;
}

@media(max-width: 1200px) {
    #event-page .content {
        width: 80%;
        margin-inline: 10%;
    }
}

@media(max-width: 900px) {
    #event-page .content {
        width: calc(100% - 40px);
        margin-inline: 20px;
    }
}
#event-page .info-box {
    display: flex;
    width: 100%;
    height: 200px;
    border-radius: 100px;
    margin-top: 40px;
    border: 2px solid var(--mid-grey);
}

#event-page .info-box .infos {
    margin-left: 100px;
    width: calc(100% - 100px - 350px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}

#event-page .info-box .infos .entry {
    display: flex;
    align-items: center;
}

#event-page .info-box .infos .entry svg {
    margin-right: 20px;
    width: 20px;
}

#event-page .info-box .image-box {
    width: 350px;
    float: right;
    height: 204px;
    margin-top: -2px;
    margin-right: -4px;
}

@media (max-width: 700px) {
    #event-page .info-box {
        flex-direction: column;
        height: 350px;
    }
    #event-page .info-box .infos {
        width: calc(100% - 200px);
        height: 120px;
    }

    #event-page .info-box .image-box {
        width: 100%;
        height: 250px;
    }
}

@media (max-width: 450px) {
    #event-page .info-box .infos {
        margin-inline: 50px;
        width: calc(100% - 100px);
    }
}


#event-page .text {
    margin-top: 60px;
}

#event-page .text p {
    min-height: 20px;
}

#event-page .more-events {
    margin-top: 50px;
}

#event-page .more-events p {
    color: var(--grey);
    line-height: 23px;
}

#event-page .more-events p.title {
    margin-bottom: 13px;
}

#event-page .more-events p.show-all {
    cursor: pointer;
    color: black;
}

#event-page .more-events p.show-all:hover {
    text-decoration: underline;
}

#event-page .helper {
    margin-top: 50px;
}