.box-container {
    display: grid;
    margin-block: 20px;
    gap: 20px;
}

.box-container.elements2 {
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
}

@media (min-width: 1700px) {
    .box-container {
        gap: 30px 30px;
    }
    .box-container.elements2 {
        grid-template-columns: calc(50% - 15px) calc(50% - 15px);
    }
    .box-container.elements3 {
        grid-template-columns: calc(33% - 20px) calc(34% - 20px) calc(33% - 20px);
      }
}



.box-container.elements4 {
    grid-template-columns: calc(25% - 15px) calc(25% - 15px) calc(25% - 15px) calc(25% - 15px);
    gap: 20px;
}

@media (max-width: 1300px) {
    .box-container.elements4 {
        gap: 20px;
    }
}

@media (max-width: 1050px) {
    .box-container.elements4 {
        grid-template-columns: calc(50% - 15px) calc(50% - 15px);
    }
}

@media (max-width: 620px) {
    .box-container.elements4 {
        grid-template-columns: 100%;
    }
}

 
.box-container.elements3 {
    grid-template-columns: calc(33% - 10px) calc(34% - 20px) calc(33% - 10px);
}

.box {
    background-color: #fff;
    box-shadow: 0px 0px 99px #0000001A;
    border-radius: 10px;
    display: grid;
    --height: 235px;
}

.box .left {
    width: 40%;
    float: left;
    height: var(--height);
    --bottom-height: 34px;
    --top-height: 21px;
}

.box .left .bottom-left {
    width: 100%;
    margin-bottom: 0;
    height: var(--bottom-height);
    display: flex;
    overflow-x: hidden;
}

.box .left .bottom-left p {
    font-weight: 600;
}

.box .left .bottom-left .round-info {
    --height: var(--bottom-height);
}

.box .right {
    width: 60%;
    float: right;
}

.box .right.right-bottom .img{
    --height: 170px;
}

.box .right.right-bottom .bottom {
    height: 60px;
    margin-top: 20px;
    margin-inline: 40px;
}

.box .right.right-bottom .bottom .info {
    display: flex;
    align-items: center;
    width: 100%;
    margin-block: 6px;
    height: 20px;
}

.box .right.right-bottom .bottom .info svg {
    width: 30px;
    height: 15px;
}

.box .right.right-bottom .bottom .info p {
    color: var(--grey);
  float: left;
}

.box.text-small .right.right-bottom .bottom {
    margin-inline: 0;
    justify-content: center;
    display: flex;
    flex-direction: column;
}


@media (max-width: 1350px) {
    .box-container.elements2 {
        grid-template-columns: 100%;
    }
    .box .right {
        width: 50%;
        margin-inline: 5%;
    }
    .box-container.elements3 {
        grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    }
}

@media (max-width: 900px) {
    .box-container.elements3 {
        grid-template-columns: 100%;
    }
}

@media (max-width: 650px) {
    .box-container.elements2 .box{
        --height: 170px;
    }
    .box-container.elements2 .box .img{
        --height: 170px;
    }
    .box-container.elements2 .box .content {
        padding: 15px;
        width: calc(100% - 30px);
    }
}

.box .content {
    padding: 30px;
    width: calc(100% - 60px);
    height: var(--height);
    overflow: hidden;
}

.box .date {
    color: var(--grey);
    line-height: var(--top-height);
    font-weight: 500;
}

.box.day .date {
    color: var(--main);
}

.box.day .date span {
    margin-left: 20px;
    color: var(--grey);
    opacity: .6;
}

.box .img {
    border-radius: 1000px;
    background-size: cover;
    background-position: center;
    height: var(--height);
}

.box .img.placeholder {
    background-image: url("../../assets/img/img1.webp");
}

.box h1 {
    line-height: 36px;
    letter-spacing: .5px;
    height: calc(var(--height) - var(--bottom-height) - var(--top-height));
}

.box.text-small h1 {
    font-size: 23px;
    line-height: 21px;
}

.box.hover, .club-box {
    transition: margin .15s;
    cursor: pointer;
}

.box.hover:hover, .club-box:hover {
    margin-top: -3px;
    margin-bottom: 3px;
}

.club-box .box {
    width: calc(100% - 60px);
    padding: 30px;
}

.club-box .logo {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 150px;
}

.club-box h3 {
    margin: 20px;
}

.event-box .info {
    width: 100%;
    margin-block: 6px;
    height: 20px;
}

.event-box .info svg {
    height: 15px;
    width: 50px;
    float: left;
}

.event-box.text-small .info svg {
    width: 35px;
}

.event-box .info p {
    color: var(--grey);
    float: left;
}

@media (max-width: 600px) {
    .box.text-small h1 {
        font-size: 20px;
    }
}

@media (max-width: 500px) {
    .box-container.elements2 .box .img {
        --height: 150px;
        margin-block: 10px;
    }
}

.video-box {
    width: calc(500px - 40px);
    float: right;
    padding: 20px;
}

.video-box iframe {
    width: 90%;
    margin-inline: 5%;
    height: 240px;
    margin-top: 20px;
}

.aider-box h1 {
    width: 400px;
}

.box2 .content .top {
    height: calc(var(--height) - 70px);
    width: 100%;
    float: left;
    display: flex;
}

.box2 .content .top .img {
    width: calc(100% - 200px);
    height: calc(var(--height) - 70px -15px);
}

.box2 .content .top .infoplace {
    width: 180px;
    margin-left: 20px;
    
}

.box2 .content .infoplace .date {
    font-size: 14px;
    height: 20px;
}

.box2 .content .infoplace .date span {
    font-weight: 600;
    float: right;
}

.box2 .content .text {
    height: 65px;
    width: 100%;
    float: left;
    margin-top: 15px;
    font-weight: 600;
    font-size: 21px;
    display: flex;
    align-items: center;
}

.box2 .content .infoplace .bottom-left {
    height: calc(var(--height) - 70px - 20px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.box2 .content .infoplace .bottom-left .info {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
}

.box2 .content .infoplace .bottom-left .info svg {
    width: 20px;
    height: 20px;
    float: left;
}


.box2 .content .infoplace .bottom-left .info p {
    margin-left: 15px;
    width: calc(100% - 35px);
    opacity: .6;
}