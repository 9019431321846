.bal-checkbox {
    width: 100%;
    display: flex;
    cursor: pointer;
    margin-block: 10px;
}

.bal-checkbox .check {
    width: 40px;
    height: 27px;
    background-color: var(--lighter-grey);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bal-checkbox .check svg {
    width: 22px;
    fill: var(--main);
    display: none;
}

.bal-checkbox.active .check svg {
    display: block;
}

.bal-checkbox a {
    font-weight: 600;
    font-size: 20px;
    margin-left: 20px;
    letter-spacing: .5px;
}