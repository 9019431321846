

#club-page .title-space {
    width: 100%;
    height: 150px;
}

#club-page .title-space img {
    max-height: 150px;
    float: left;
    margin-right: 50px;
}

#club-page .title-space h1 {
    font-size: 45px;
    line-height: 50px;
    font-weight: 700;
    margin-block: 20px;
}

#club-page .title-space h2 {
    font-size: 25px;
    font-weight: 500;
}

#club-page .content {
    margin-top: 80px;
    display: grid;
    gap: 50px;
    grid-template-columns: 400px calc(100% - 100px - 400px - 400px) 400px;
    grid-template-areas: "a b c"
                         "d b c"
                         "e b f";
}

#club-page .content.noimages {
    grid-template-columns: calc(100% - 100px - 400px) 400px;
    grid-template-areas: "b c"
    "b c"
    "b c";
}

#club-page .content .img {
    width: 100%;
}

#club-page .content .img:nth-child(1) {
    grid-area: a;
}

#club-page .content .img:nth-child(2) {
    grid-area: d;
}

#club-page .content .img:nth-child(3) {
    grid-area: e;
}

#club-page .content .img:nth-child(4) {
    grid-area: f;
}

#club-page .text {
    grid-area: b;
    font-size: 20px;
    padding-bottom: 50px;
}

#club-page .text p {
    margin-top: 10px;
    min-height: 15px;
}

#club-page .info-box {
    grid-area: c;
    background-color: var(--white);
    padding-block: 50px;
    height: max-content;
    display: block;
}

#club-page .info-box .field {
    margin-inline: 70px;
    margin-block: 25px;
    font-size: 16px;
}

#club-page .info-box .field svg {
    width: 15px;
    overflow: visible;
    margin-left: -28px;
    float: left;
    margin-top: 2px;
}

#club-page .info-box .field h3 {
    font-weight: 500;
    line-height: 25px;
    font-size: 17px;
    margin-bottom: 5px;
}

#club-page .info-box .field p {
    line-height: 23px;
    color: var(--grey);
}

#club-page .info-box .field p a {
    text-decoration: none;
    color: black;
    opacity: .7;
}

#club-page .info-box .field p a:hover {
    text-decoration: underline;
}

@media (max-width: 1300px) {
    #club-page .content {
        margin-top: 80px;
        display: grid;
        gap: 50px;
        grid-template-columns: 300px calc(100% - 100px - 300px - 300px) 300px;
        grid-template-areas: "a b c"
                             "d b c"
                             "e b f";
    }
    #club-page .content .img {
        height: 200px;
    }
}

@media (max-width: 1200px) {

    #club-page {
        margin-inline: 10%;
        width: calc(80% - 60px);
    }

    #club-page .title-space h1 {
        font-size: 30px;
        line-height: 33px;
    }
    #club-page .title-space h2 {
        font-size: 21px;
    }

    #club-page .info-box {
        order: 0;
        padding-block: 10px;
        margin-top: 40px;
    }
    #club-page .content {
        display: flex;
        flex-flow: column;
    }
    #club-page .text {
        
        order: 2;
    }
    #club-page .img:nth-child(1) {
        order: 1;
    }
    #club-page .img:nth-child(2) {
        order: 3;
    }
    #club-page .title-space {
        height: fit-content;
    }
}

@media (max-width: 900px) {
    #club-page {
        margin-inline: 10px;
        width: calc(100% - 80px);
    }
    #club-page .title-space img {
        margin-right: 20px;
    }
    #club-page .info-box {
        margin-inline: 20px;
    }
}

@media (max-width: 600px) {
    #club-page {
        margin-inline: 10px;
        width: calc(100% - 30px);
    }

}

@media (max-width: 500px) {
    #club-page .title-space {
        flex-direction: column;
    }

    #club-page .title-space img {
        margin-right: 0;
        width: 60%;
        height: fit-content;
        max-height: unset;
    }
}

@media (max-width: 350px) {
    #club-page .title-space img {
        width: 100%;
    }
}

#club-page .section .title-space {
    display: flex;
}