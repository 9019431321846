  /** Filson Pro **/
  @font-face {
    font-family: 'Filson Pro';
    src: url('../../assets/fonts/filson/FilsonProThin.otf');
    font-weight: thin;
    font-weight: 100;
  }

  @font-face {
    font-family: 'Filson Pro';
    src: url('../../assets/fonts/filson/FilsonProThinItalic.otf');
    font-weight: thin;
    font-weight: 100;
    font-style: italic;
  }

  @font-face {
    font-family: 'Filson Pro';
    src: url('../../assets/fonts/filson/FilsonProLight.otf');
    font-weight: 300;
    font-weight: light;
  }

  @font-face {
    font-family: 'Filson Pro';
    src: url('../../assets/fonts/filson/FilsonProLightItalic.otf');
    font-weight: 300;
    font-weight: light;
    font-style: italic;
  }

  @font-face {
    font-family: 'Filson Pro';
    src: url('../../assets/fonts/filson/FilsonProRegular.otf');
    font-weight: 400;
    font-weight: normal;
  }

  @font-face {
    font-family: 'Filson Pro';
    src: url('../../assets/fonts/filson/FilsonProRegularItalic.otf');
    font-weight: 400;
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: 'Filson Pro';
    src: url('../../assets/fonts/filson/FilsonProMedium.otf');
    font-weight: 500;
    font-weight: medium;
  }

  @font-face {
    font-family: 'Filson Pro';
    src: url('../../assets/fonts/filson/FilsonProMediumItalic.otf');
    font-weight: 500;
    font-weight: medium;
    font-style: italic;
  }

  @font-face {
    font-family: 'Filson Pro';
    src: url('../../assets/fonts/filson/FilsonProBold.otf');
    font-weight: 700;
  }

  @font-face {
    font-family: 'Filson Pro';
    src: url('../../assets/fonts/filson/FilsonProBoldItalic.otf');
    font-weight: 700;
    font-style: italic;
  }
