.img {
    --height: 250px;
    height: var(--height);
    border-radius: 250px;
    background-size: cover;
    background-position: center;
}

.img.blur {
    overflow: hidden;
    width: 100%;
    min-width: 100%;
}

.img.blur img:not(.fullSize) {
    width: 100%;
    filter: blur(20px);
}

.img.blur img.fullSize {
    height: var(--height);
    position: absolute;
    margin: 0;
}