#article-page h1, #article-page h2 {
    text-align: center;
    font-family: "Badoni MT", sans-serif;
}

#article-page h1 {
    font-size: 30px;
    margin-top: 50px;
}

#article-page h2 {
    font-weight: 400;
    font-size: 22px;
    margin-top: 12px;
    margin-bottom: 50px;
}

#article-page .section {
    width: 70%;
    margin-inline: 15%;
}

#article-page .infos {
    height: 80px;
    width: 100%;
}

#article-page .info {
    height: 30px;
    width: auto;
    float: left;
}

#article-page .info svg {
    float: left;
    fill: var(--grey);
    height: 12px;
    margin-block: 9px;
    margin-right: 10px;
}

#article-page .info p {
    float: left;
    color: var(--grey);
    line-height: 30px;
    font-size: 14px;
}

#article-page .info:not(:first-child) {
    margin-left: 40px;
}

#article-page .content {
    margin-top: 10px;
    width: 100%;
}

#article-page .content .passage {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 33% 34% 33%;
    margin-block: 25px;
}

#article-page .content .passage .text {
    line-height: 15px;
    font-size: 14px;
}

#article-page .content .passage .text p {
    line-height: 15px;
    min-height: 15px;
}

#article-page .content .passage.text-only, #article-page .content .passage.img-only.img1 {
    grid-template-areas: "a a a";
}

#article-page .content .passage.img-only.img1 .image-box {
    grid-area: a;
}

#article-page .content .passage.text-only .text {
    grid-area: a;
}

#article-page .content .passage.img-only.img1 .img {
    grid-area: a;
}

#article-page .content .passage.img-only.img2 {
    grid-template-columns: 50% 50%;
}

#article-page .content .passage.img-only.img2 .img {
    --height: 200px;
}

#article-page .content .passage.img-only.img3 .img {
    --height: 150px;
}

#article-page .content .passage.img1 {
    grid-template-areas: "a b b";
}

#article-page .content .passage.img1 .text {
    grid-area: b;
    margin-left: 20px;
}

#article-page .content .passage.img1 .img {
    grid-area: a;
    --height: 150px;
}

#article-page .content .passage.img1.right-img {
    grid-template-areas: "b b a";
}

#article-page .content .passage.img2 {
    grid-template-areas: "a b c";
}

#article-page .content .passage.img2 .img:nth-child(2) {
    grid-area: a;
}

#article-page .content .passage.img2 .img {
    grid-area: c;
    --height: 150px;
}

#article-page .content .passage.img2 .text {
    grid-area: b;
}

@media (max-width: 1200px) {
    #article-page .section {
        width: 95%;
        margin-inline: 2.5%;
    }
}

@media (max-width: 900px) {
    #article-page .section {
        width: 100%;
        margin-inline: 0;
    }
}

@media (max-width: 750px) {
    #article-page .passage.img-only.img3 {
        grid-template-columns: 100%;
        row-gap: 20px;
    }
    #article-page .passage.img-only.img3 .img{
        width: 450px;
        margin-right: calc(100% - 450px);
    }
}

@media (max-width: 500px) {
    #article-page .passage.img-only.img3 .img{
        width: 100%;
        margin-right: 0;
    }
}

@media (max-width: 750px) {
    #article-page .infos {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        height: fit-content;
    }
    #article-page .info {
        height: unset;
    }
    #article-page .info:not(:first-child) {
        margin-left: 0;
    }
}