header {
    --height: 60px;
    --padding-inline: 75px;
    width: calc(100vw - var(--padding-inline)*2);
    background-color: var(--main);
    height: var(--height);
    padding: 7.5px;
    padding-inline: var(--padding-inline);
    line-height: var(--height);
    margin-bottom: 30px;
    overflow: hidden;
    position: fixed;
    top: 0;
    z-index: 10;
}

header .search {
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(var(--height)/2);
    margin-block: calc(var(--height)/4);
}

header .search svg {
    height: 19px;
    cursor: pointer;
}

header span {
    color: var(--light-main);
    font-weight: 500;
}

header .logo {
    float: left;
    display: flex;
    height: var(--height);
    cursor: pointer;
}

header .logo img {
    float: left;
    margin-right: 35px;
    transition: margin .2s ease;
}

header .logo:hover img {
    margin-right: 30px;
}

header .logo h1 {
    font-size: 25px;
    color: var(--white);
}

header ul {
    list-style: none;
    float: right;
}

header ul li {
    float: left;
    margin-inline: 24px;
    padding-inline: 4px;
    font-size: 19px;
    font-weight: 600;
    cursor: pointer;
    line-height: 60px;
}

header ul li a {
    text-decoration: none;
    color: var(--white);
}

header ul li hr {
    margin-top: -15px;
    width: 0;
    margin-inline: 12.5%;
    padding: 0;
    background-color: var(--white);
    transition: width .3s ease;
    height: 2px;
    border: none;
    border-radius: 3px;
}

header ul li:hover hr {
    width: 25px;
    opacity: 1;
}

header ul li.active hr {
    width: 75%;
    opacity: 1;
}

header.mobile.open ul {
    right: 0;
}

header.mobile ul {
    width: 250px;
    height: calc(100vh - (var(--height) + 15px));;
    position: fixed;
    background-color: var(--main);
    right: -250px;
    top: calc(var(--height) + 15px);
    z-index: 5;
    transition: right .5s ease;
}

header.mobile ul li {
    width: 100%;
}

header.mobile ul li hr {
    margin-inline: 0;
}

header.mobile ul li.active hr {
    width: 30%;
}

header.mobile .pagename {
    float: right;
    font-weight: 500;
    font-size: 15px;
    color: var(--white);
}

header.mobile .search {
    width:  100px;
    height: 22px;
    margin-block: calc((var(--height) - 22px) / 2)
}

header.mobile .burger {
    --burger-height: 16px;
    --burger-width: 23px;
    height: var(--burger-height);
    float: right;
    width: auto;
    cursor: pointer;
    margin-block: calc((var(--height) - var(--burger-height)) / 2)
}

header.mobile .burger .line {
    background-color: var(--white);
    width: var(--burger-width);
    height: 2px;
    border-radius: 1px;
    transition: all .3s ease;
}

header.mobile .burger .line:nth-child(2) {
    margin-block: 0;
    margin-block: calc((var(--burger-height) - 6px) / 2)
}

header.mobile.open .burger .line:nth-child(2) {
    opacity: 0;
}

header.mobile.open .burger .line:nth-child(3) {
    transform: rotate(-45deg);
    margin-top: -14px;
}

header.mobile.open .burger .line:nth-child(1) {
    transform: rotate(45deg);
    margin-top: 5px;
}

@media (max-width: 700px) {
    header {
        --height: 30px;
    }
    header .logo h1 {
        font-size: 17px;
    }
}

@media (max-width: 550px) {
    header {
        --padding-inline: 30px;
    }
    header.mobile .burger {
        --burger-height: 12px;
        --burger-width: 18px;
    }
    header.mobile.open .burger .line:nth-child(3) {
        margin-top: -10px;
    }
    header.mobile .search {
        width: 60px;
    }
    header.mobile .search svg {
        height: 13px;
    }
    header.mobile .pagename {
        font-size: 13px;
    }
}