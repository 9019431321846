.multiple-select {
    height: 100px;
}

.multiple-select p {
    font-size: 18px;
    font-weight: 500;
}

.multiple-select svg.chevron-down {
    position: absolute;
    right: 15px;
    height: 10px;
    top: 17.5px;
}

.multiple-select.active svg.chevron-down {
    display: none;
}

.multiple-select .overlay {
    width: 100vw;
    height: 100vh;
    background-color: rgba(50, 50, 50, .4);
    top: 0;
    left: 0;
    position: absolute;
    display: none;
}

.multiple-select.active .overlay {
    display: block;
    position: fixed;
}

.multiple-select:not(.active) .dropdown {
    border: none;
    border-radius: 25px;
    height: 45px;
    overflow: hidden;
    background-color: #e8e8e8;
}

.multiple-select.active .dropdown {
    z-index: 5;
}

.multiple-select .dropdown {
    position: relative;
    background-color: var(--white);
    width: calc(400px - 24px);
    border-radius: 14px;
    overflow:hidden;
    max-height: 300px;
    overflow-y: auto;
    width: 300px;
}

.multiple-select .dropdown p.add {
    opacity: .6;
    transition: opacity .2s;
}

.multiple-select .dropdown p.add:hover {
    opacity: .9;
}

.multiple-select .dropdown p {
    height: 30px;
    font-size: 18px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-block: 7.5px;
    color: black;
    padding-inline: 12px;
}

.multiple-select .dropdown p.empty {
    opacity: .7;
}

.multiple-select.active .dropdown p.active {
    font-weight: 600;
}

.multiple-select.active .dropdown p:not(.add):hover {
    background-color: var(--light-grey);
}

.multiple-select .dropdown p .delete {
    display: none;
}

.multiple-select.active .dropdown p .delete {
    display: block;
    float: right;
    position: absolute;
    right: -20px;
    transition: right .3s ease;
}

.multiple-select.active .dropdown p:not(.add):hover .delete {
    right: 20px;
}

.multiple-select.active .dropdown p .delete svg {
    display: block;
    position: relative;
    width: 15px;
    opacity: .6;
    transition: opacity .2s;
}

.multiple-select.active .dropdown p .delete:hover svg {
    opacity: 1;
}