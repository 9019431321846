
.schedule-page .expand {
    color: var(--main);
    font-weight: 600;
    font-size: 13px;
}

.schedule-page .expand.disabled {
    color: var(--mid-grey);
    opacity: .7;
}

.schedule-page .expand:not(.disabled):hover {
    text-decoration: underline;
    cursor: pointer;
}

.schedule-page .calendar-div {
    width: calc(100% - 70px - 60px);
    display: flex;
    gap: 70px;
    margin-top: 80px;
    margin-inline: 5%;
}



.schedule-page .react-calendar {
    width: 100%;
    float: left;
    max-width: 600px;
}

.schedule-page .current-day {
    width: 100%;
}

.schedule-page .current-day h2 {
    font-size: 15px;
    font-weight: 600;
    font-family: "Filson Pro", sans-serif;
    height: 40px;
}

.schedule-page .current-day p.info {
    text-align: center;
    margin-top: 70px;
    color: var(--grey);
    opacity: .8;
}

.schedule-page .current-day h2 span {
    float: right;
    font-size: 12px;
    color: var(--grey);
}

@media (max-width: 1100px) {
    .schedule-page .calendar-div {
        width: 100%;
        margin-inline: 0;
    }
}

@media (max-width: 950px) {
    .schedule-page .calendar-div {

        flex-direction: column;
    }
    .schedule-page .react-calendar {
        width: 100%;
        float: left;
    }
    
    .schedule-page .current-day {
        width: 100%;
    }
}

@media (min-width: 1350px) {

    .schedule-page .calendar-div {
        gap: 200px;
        width: calc(100% - 60px - 200px);
    }
}