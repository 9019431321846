*, html, body {
    margin: 0;
    padding: 0;
    font-family: "Schibsted", sans-serif;
    color: var(--black);
    scroll-behavior: smooth;
}

.default-font {
    font-family: "Schibsted", sans-serif;
}

body {
    background-color: var(--white);
    overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Filson Pro', sans-serif;
}

h1 {
    font-size: 27px;
}

h3 {
    font-size: 20px;
}

p.grey {
    color: var(--grey);
}

.page {
    padding: 30px;
    margin-top: 60px;
}

.page .section h3.no-content {
    height: 35px;
    margin-block: 120px;
    width: 100%;
    text-align: center;
    opacity: .7;
}

.page .section h3.no-content.big-spacing {
    margin-block: 200px;
}

.page .section.white h3.no-content {
    color: white;
}

.page .section {
    margin-top: 25px;
}

@media (min-width: 1700px) {
    .page {
        padding-inline: 150px;
    }
}

.page p.navinfo a {
    cursor: pointer;
    color: var(--grey);
}

.page p.navinfo a:hover {
    text-decoration: underline;
}

@media (max-width: 600px) {
    .page {
        padding: 15px;
    }
}