#aider-page p.text {
    margin-top: 20px;
}

#aider-page .register {
    margin-top: 30px;
}

#aider-page .register .error {
    color: var(--error);
}

#aider-page .register .success {
    text-decoration: underline;
}

#aider-page .register .search-button {
    color: white;
    width: 130px;
}


#aider-page .register input {
    margin-right: 30px;
    border: none;
    outline: none;
    border-bottom: 2px solid black;
    padding-bottom: 6px;
    width: 300px;
}

#aider-page .next {
    margin-top: 70px;
    margin-bottom: 100px;
}

#aider-page .next .search {
    height: 100px;
    margin-top: 100px;
}

#aider-page .next .search .category {
    display: flex;
    width: 400px;
    align-items: center;
    height: 40px;
    float: left;
}

#aider-page .next .search .category h2 {
    float: left;
    font-size: 18px;
    margin-right: 30px;
}

#aider-page .next .search .category .multiple-select {
    height: 40px;
}

@media (max-width: 920px) {
    #aider-page .next .search {
        height: 140px;
    }
    #aider-page .next .search .category {
        width: 100%;
    }

    #aider-page .next .search .search-button {
        margin-top: 20px;
        margin-left: 60px;
    }
    #aider-page .next .search .search-button.active {
        width: calc(80% - 120px);
    }
}

#aider-page .display-more {
    cursor: pointer;
    font-weight: 600;
    font-size: 13px;
}

#aider-page .display-more:not(.disabled):hover {
    text-decoration: underline;
}

#aider-page .display-more.disabled {
    opacity: .7;
    color: var(--mid-grey);
}

#aider-info .title {
    color: var(--main);
    font-weight: 600;
}

#aider-info .title span {
    color: var(--main);
    margin-left: 30px;
}

#aider-info h1 {
    margin-top: 30px;
    margin-bottom: 30px;
}

#aider-info .infos {
    display: flex;
    align-items: center;
    height: 120px;
    flex-direction: column;
    margin-left: 30px;
}

#aider-info .infos .info {
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
}

#aider-info .infos .info svg {
    height: 15px;
    width: 15px;
    margin-right: 20px;
}

#aider-info .description {
    margin-top: 20px;
}

#aider-info .bottom-info {
    margin-top: 60px;
    display: flex;
}

#aider-info .bottom-info .contact {
    width: 100%;
}

#aider-info .bottom-info h2 {
    margin-bottom: 20px;
}

#aider-info .bottom-info .contact .option {
    display: flex;
    align-items: center;
    height: 50px;
}

#aider-info .bottom-info .contact .option a {
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
}

#aider-info .bottom-info .contact .option a:hover {
    text-decoration: underline;
}

#aider-info .bottom-info .contact .option svg {
    
    width: 20px;
    height: 20px;
    margin-right: 20px;
}

#aider-info .bottom-info .contact .option svg path {
    fill: var(--main);
}

#aider-info .bottom-info .event {
    width: 100%;
}

#aider-info .bottom-info .event .event-box {
    width: 600px;
}