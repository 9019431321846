#home-page .img-banner {
    width: 100vw;
    position: absolute;
    left: 0;
    height: 500px;
    margin-top: -60px;
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    z-index: -1;
}

#home-page .overlap-box {
    border-radius: 15px;
    background-color: var(--white);
    padding: 40px;
}

#home-page .overlap-box h1 {
    margin-bottom: 30px;
}

#home-page .overlap-box p {
    line-height: 20px;
}

#home-page .about-box {
    width: calc(60% - 80px);
    margin-top: 300px;
}

@media (max-width: 1350px) {
    #home-page .about-box {
        width: calc(100% - 80px);
    }
}

@media (max-width: 550px) {
    #home-page .overlap-box {
        padding: 10px;
    }
    #home-page .about-box {
        width: calc(100% - 20px);
    }
}

#home-page .help-box {
    margin-top: 100px;
    width: 700px;
    padding-left: 400px;
    margin-left: -370px;
}

@media (max-width: 800px) {
    #home-page .help-box {
        width: calc(100% - 80px);
    }
}

@media (max-width: 600px) {
    #home-page .help-box {
        width: calc(100% - 70px);
        padding-right: 40px;
    }
}

#home-page .search-button {
    margin-top: 50px;
    width: 270px;
}

#home-page .search-button a {
    text-decoration: none;
}

@media (max-width: 380px) {
    #home-page .search-button {
        width: 80%;
    }
}

#home-page .help-banner {
    margin-top: -150px;
    height: 600px;
}

#home-page .section.white {
    margin-top: 50px;
    margin-bottom: 150px;
}

#home-page .section.white h1.white {
    color: white;
}

#home-page .culture {
    width: 100%;
    height: 300px;
}

#home-page .section h1 {
    margin-bottom: 30px;
}

#home-page .culture h1, #home-page .culture p {
    width: calc(100% - 550px);
    float: left;    
}

@media (max-width: 1000px) {
    #home-page .culture {
        height: 600px;
    }
    #home-page .culture .video-box {
        margin-inline: calc(50% - 250px);
    }
    #home-page .culture h1, #home-page .culture p {
        width: unset;
        margin-top: 40px;
    }
}

@media (max-width: 500px) {
    #home-page .culture .video-box {
        width: calc(100% - 20px);
        padding: 10px;
        margin-inline: unset;
    }
    #home-page .culture .video-box iframe {
        width: 100%;
        margin-inline: unset;
    }
}

#home-page .club-ad {
    width: calc(100% - 200px);
    padding-inline: 100px;
    padding-block: 50px;
    margin-top: 100px;
    border-radius: 300px;
    border: 1px solid var(--mid-grey);
}

@media (max-width: 650px) {
    #home-page .club-ad {
        width: calc(100% - 50px);
        padding-inline: 25px;
        border-radius: 50px;
    }
}