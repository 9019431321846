#search .searchSpace {
    margin-top: 30px;
    display: flex;
    gap: 75px;
}

#search .searchSpace .results {
    width: 60%;
}

#search .searchSpace .results .body {

}

#search .searchSpace .results .header {
    height: 40px;
}

#search .searchSpace .results .header p {
    float: right;
    font-size: 14px;
    color: var(--mid-grey);
    line-height: 30px;
}

#search .searchSpace .filter {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0px 0px 99px #0000001A;
    width: calc(40% - 75px - 50px);
    padding: 25px;
    height: fit-content;
}

#search .searchSpace .filter h1 {
    font-size: 25px;
}

#search .searchSpace .filter h2 {
    font-size: 21px;
    margin-top: 40px;
    margin-bottom: 20px;
}

#search .searchSpace .filter .checkbox {
    margin-top: 34px;
}

#search .searchSpace .results .body .empty {
    margin-top: 50px;
}

#search .searchSpace .results .body .empty p {
    text-align: center;
    font-size: 20px;
    opacity: .6;
    font-weight: 600;
}

#search .searchSpace .results .body .empty svg {
    width: 100px;
    margin-inline: calc(50% - 50px);
    margin-bottom: 30px;
    fill: var(--mid-grey);
}