.multiple-box {
    background-color: #fff;
    box-shadow: 0px 0px 99px #0000001A;
    border-radius: 10px;
    padding: 10px;
}
.multiple-box .box {
    background-color: transparent;
    box-shadow: none;
}

.multiple-box .box:hover {
    margin-right: -2px;
    margin-left: 2px;
    margin-top: 0;
    margin-bottom: 0;
}

.multiple-box hr {
    border: none;
    background-color: var(--grey);
    height: 1.2px;
    width: 60%;
    margin-inline: 20%;
}