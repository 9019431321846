.search-result .header .type {
    font-weight: 600;
    font-size: 15px;
    float: left;
}

.search-result .header .date {
    color: var(--light-main);
    font-weight: 600;
    font-size: 15px;
    opacity: .7;
    float: left;
    margin-left: 50px;
}

.search-result .content {
    overflow: hidden;
}

.search-result .content h1 {
    font-size: 22px;
    margin-bottom: 10px;
}

.search-result {
    display: flex;
    margin-bottom: 40px;
    margin-top: 20px;
    cursor: pointer;
    transition: margin .2s;
}

.search-result:hover {
    margin-right: -4px;
    margin-left: 4px
}

.search-result .img {
    width: 400px;
    padding-block: 30px;
    margin-right: 30px;
}