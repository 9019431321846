.multiple-choice {
    width: 100%;
    display: flex;
    background-color: var(--lighter-grey);
    border-radius: 20px;
}

.multiple-choice a {
    width: 100%;
    text-align: center;
    font-weight: 600;
    padding-block: 7px;
    border-radius: 20px;
    transition: background .2s, color .2s;
    cursor: pointer;
}

.multiple-choice a.active {
    background-color: var(--main);
    color: var(--white);
}