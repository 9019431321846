.simple-parallax {
    perspective: 1px;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .simple-parallax-el  {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .simple-parallax .background {
    transform: translateZ(0);
  }
  .simple-parallax .foreground {
    transform: translateZ(-1px);
  }