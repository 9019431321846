:root {
    --main: #2d2384;
    --light-main: #a29be7;
    --white: #fff;
    --black: rgb(20, 20, 20);
    --light-grey: #FBFBFB;
    --lighter-grey: #eeeeee;
    --grey: #919191;
    --dark-grey: #070707;
    --mid-grey: #C2C2C2;
    --semi-dark-grey: #2e2e2e;
    --very-light-grey: #F5F5F5;
    --error: #ed7131;
}