.react-calendar__navigation__label__labelText {
    font-family: 'Filson Pro', sans-serif;
}

.react-calendar__navigation {
    height: 40px;
}

.react-calendar__navigation__label {
    border: none;
    background: none;
    font-size: 15px;
    font-weight: 600;
    width: 170px;
    text-align: center;
}

.react-calendar__navigation__arrow {
    border-radius: 50%;
    border: none;
    width: 20px;
    height: 20px;
    cursor: pointer;
    background-color: #fff;
    box-shadow: 0px 0px 99px #0000001A;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    background-color: #fff;
    box-shadow: 0px 0px 99px #0000001A;
    border-radius: 15px;
}

.react-calendar__month-view__weekdays abbr {
    color: var(--main);
    line-height: 40px;
    font-size: 15px;
    text-decoration: none;
    font-weight: 600;

}

.react-calendar__month-view__days__day abbr {
    font-size: 15px;
}

.react-calendar__month-view__days {
    row-gap: 15px;
    margin-top: 15px;
}

.react-calendar__month-view__weekdays__weekday, .react-calendar__month-view__days__day {
    flex-basis: 50px !important;
    margin-inline: calc(((98% / 7) - 51px));
}

.react-calendar__month-view__days__day {
    border: none;
    background-color: transparent;
    height: 50px;
    border-radius: 50%;
    color: black;
    cursor: pointer;
}

.react-calendar__month-view__days__day:hover {
    background-color: var(--very-light-grey);
}

.react-calendar__month-view__days__day.highlight {
    background-color: var(--light-main);
    line-height: 11px;
}

.react-calendar__month-view__days__day.highlight.selected::before {
    width: 40px;
    height: 40px;
    margin: calc(50% - 20px);
}

.react-calendar__month-view__days__day.selected:not(.highlight) {
    background-color: var(--very-light-grey);
}

.react-calendar__month-view__days__day.highlight:hover::before {
    width: 45px;
    height: 45px;
    margin: calc(50% - 22.5px);
}

.react-calendar__month-view__days__day.highlight::before {
    content: "";
    background-color: var(--main);
    width: 50px;
    height: 50px;
    display: block;
    border-radius: 50%;
    margin: calc(50% - 25px);
    transition: margin .2s ease, width .2s ease, height .2s ease;
}

.react-calendar__month-view__days__day.highlight abbr {
    position: absolute;
    margin-top: -31px;
    margin-left: -25px;
    width: 50px;
}

.react-calendar__month-view__days__day.highlight abbr {
    color: var(--white);
}

.react-calendar__month-view__days__day--neighboringMonth {
    background-color: var(--very-light-grey);
}

.react-calendar__month-view__days__day--neighboringMonth abbr {
    color: var(--mid-grey);
}