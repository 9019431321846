#error {
    display: flex;
    padding-inline: 15%;
    height: 30vh;
    margin-top: calc(50vh - 15vh - 100px - 70px);
}

#error img {
    width: 50%;
}

#error .text {
    margin-left: 50px;
    margin-top: 40px;
    width: 50%;
}

#error .text h1 {
    font-size: 30px;
    margin-bottom: 20px;
}

#error .text p {
    font-size: 19px;
    margin-bottom: 25px;
}

#error .text a {
    color: var(--main);
    font-weight: 700;
    cursor: pointer;
    text-decoration: none;
}

#error .text a:hover {
    text-decoration: underline;
}