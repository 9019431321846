footer {
    width: calc(100vw - 100px);
    background-color: var(--semi-dark-grey);
    padding: 50px;
    padding-bottom: 20px;
    padding-top: 60px;
}

footer .links {
    width: 100%;
    display: flex;
    height: 40px;
    align-items: center;
}

footer .links span {
    font-size: 25px;
    font-weight: 600;
    color: var(--white);
}

footer .links ul {
    list-style: none;
    float: right;
    margin-left: auto;
}

footer .links ul li {
    float: left; 
    color: white;
    font-weight: 500;
    padding-inline: 10px;
    cursor: pointer;
}

footer .links ul li:hover {
    text-decoration: underline;
}

footer.absolute {
    position: absolute;
    bottom: 0;
}

@media (max-width: 800px) {
    footer .links ul {
        float: unset;
        margin-top: 30px;
        margin-bottom: 10px;
        height: 30px;
    }

    footer .links ul li {
        padding-inline: 0;
        padding-right: 20px;
    }
}

@media (max-width: 550px) {
    footer .links ul {
        height: fit-content;
        display: flex;
        flex-direction: column;
    }
    footer .links ul li {
        width: 100%;
        margin-block: 5px;
    }
}

footer .top {
    margin-bottom: 10px;
    height: 30px;
}

footer .top .icons {
    width: 100px;
    float: left;
}

footer .top .icons .mediaicon {
    width: 20px;
    transition: transform .2s;
}

footer .top .icons .mediaicon svg {
    fill: var(--white);
    width: 20px;
    cursor: pointer;
}

footer .top .icons .mediaicon:hover {
    transform: scale(1.1);
}

footer .top .up {
    color: var(--white);
    float: right;
    display: flex;
    align-items: center;
    cursor: pointer;
}

footer .top .up.disabled {
    opacity: .7;
}

footer .top .up svg {
    width: 24px;
    margin-left: 10px;
    transition: margin .2s;
}

footer .top .up:not(.disabled):hover svg {
    margin-top: -4px;
}

footer .newsletter {
    height: 150px;
}

footer .newsletter h1 {
    color: var(--white);
}

footer .newsletter .search-button {
    margin-top: 30px;
    background-color: var(--white);
    width: 250px;
}

footer .newsletter .search-button.active {
    width: 400px;
}

footer .newsletter .search-button input {
    color: var(--main);
}

footer .newsletter .search-button a {
    
    color: var(--main);
}

footer .newsletter .search-button svg path {
    fill: var(--main);
}

footer .newsletter .search-button.active svg {
    transform: none;
}

footer .newsletter-error {
    color: #ed7131;
    margin-top: 20px;
    margin-bottom: -20px;
}

footer .newsletter-success {
    color: white;
    margin-top: 50px;
    font-weight: 500;
    border-radius: 100px;
}

footer .newsletter-success span {
    color: white;
    text-decoration: underline;
}

footer .credit {
    float: left;
    width: fit-content;
}

footer .credit, footer .credit a {
    text-transform: uppercase;
    color: var(--grey);
    font-weight: 600;
}

footer .credit a {
    color: var(--mid-grey);
    cursor: pointer;
    text-decoration: none;
}

footer .credit a:hover {
    text-decoration: underline;
}

@media (max-width: 830px) {
    footer .newsletter h1 {
        font-size: 20px;
    }
}

@media (max-width: 630px) {
    footer .newsletter {
        margin-top: 20px;
    }
    footer .links {
        flex-direction: column;
        align-items: unset;
        height: unset;
    }

    footer .links ul {
        margin-left: unset;
    }
    footer {
        padding: 20px;
        width: calc(100vw - 40px);
    }
    footer .top {
        height: 60px;
        margin-block: 30px;

    }
    footer .credit {
        width: 100%;
    }
    footer .top .up {
        float: left;
        width: 100%;
        margin-top: 15px;
    }
}