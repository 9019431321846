.loader-div {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: -1;
}

.loader {
    width: 138px;
    height: 138px;
    display: inline-block;
    position: relative;
  }
  .loader::after,
  .loader::before {
    content: '';  
    box-sizing: border-box;
    width: 138px;
    height: 138px;
    border-radius: 50%;
    border: 6px solid var(--main);
    position: absolute;
    left: 0;
    top: 0;
    animation: animloader 2s linear infinite;
  }
  .loader::after {
    animation-delay: 1s;
  }


  .loader.income::after {
    opacity: 0;
  }

  @keyframes income {
    from {opacity: 0}
    to {opacity: 1}
  }
  
  @keyframes animloader {
    0% {
      transform: scale(0);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }