.round-info {
    --padding: 3px;
    float: left;
    margin-right: 20px;
    border: 2px solid var(--black);
    padding: var(--padding);
    padding-inline: 20px;
    border-radius: 290px;
    line-height: calc(var(--height) - var(--padding) * 3);
    font-size: 14px;
    font-weight: 600;
}