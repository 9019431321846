.search-input {
    --width: 400px;
    display: flex;
    align-items: center;
    width: var(--width);
    float: left;
}

.search-input input {
    width: calc(var(--width) - 30px - 2px);
    height: 20px;
    border: none;
    border-bottom: 2px solid var(--black);
    padding-left: 2px;
    transition: padding .2s, width .2s, border .2s;
    outline: none;
    background: none;
}

.search-input input:focus {
    padding-left: 6px;
    width: calc(var(--width) - 30px - 6px);
    border-color: var(--main);
}

.search-input svg {
    width: 20px;
    filter: invert(1);
    height: 20px;
    margin-left: 10px;
}