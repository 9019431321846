.search-button {
    background-color: var(--main);
    display: inline-block;
    padding: 5px;
    padding-inline: 30px;
    border-radius: 250px;
    align-items: center;
    --height: 35px;
    height: var(--height);
    cursor: pointer;
    width: 170px;
    transition: width .8s ease;
    overflow: hidden;
}

.search-button.active input {
    display: inline;
}

.search-button.active a {
    display: none;
}

.search-button.active {
    width: 400px;
}

@media (max-width: 550px) {
    .search-button.active {
        width: 80%;
    }
}

.search-button.active svg {
    transform: rotateZ(-70deg);
    margin-right: 10px;
}

.search-button input {
    display: none;
    width: calc(100% - 60px);
    background: none;
    border: none;
    color: var(--white);
    outline: none;
    font-size: 20px;
}

.search-button > * {
    
    vertical-align: middle;
}

.search-button a {
    color: var(--white);
    font-size: 20px;
}

.search-button svg {
    width: 20px;
    height: var(--height);
    fill: var(--white);
    margin-right: 17px;
    transition: transform .2s ease, margin .2s ease;
}

h1.titleWithSearch {
    float: left;
    margin-right: 70px;
}